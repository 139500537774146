<template>
  <div class="inputBox">
    <input
      class="inputCon"
      v-model="value"
      @input="upData"
      @change="change"
      :type="type"
      @keydown.enter="keydownEnter"
      :placeholder="placeholder"
      @focus="focusMethods"
      @blur="blurMethods"
    />
    <img
      v-show="showClearBtn"
      @click="clearBtn"
      :src="require('@/assets/icon/shanchuhui.png')"
      alt=""
    />
  </div>
</template>
<script setup>
import { getCurrentInstance, ref } from "vue";
const { proxy } = getCurrentInstance();
//------------------------ data -----------------------------------------
const props = defineProps({
  modelValue: {
    default: "",
  },
  change: {
    default: null,
  },
  keydownEnter: {
    default: null,
  },
  placeholder: {
    default: null,
  },
  type: {
    default: "text",
  },
});
const value = ref("");
const showClearBtn = ref(false);
const ochange = ref(props.change);
//------------------------ pageLoad -------------------------------------
//------------------------ methods --------------------------------------
// 获得焦点事件
const focusMethods = () => {
  showClearBtn.value = true;
};
// 失去焦点事件
const blurMethods = () => {
  setTimeout(() => {
    showClearBtn.value = false;
  }, 100);
};
// 上传数据
const upData = () => {
  proxy.$emit("update:modelValue", value.value);
};
// 清除按钮事件
const clearBtn = () => {
  value.value = "";
  if (!!props.change) {
    props.change();
  }
};
defineExpose({});
</script>
<style lang="scss" scoped="scoped">
@import "@/css/mixin.scss";
.inputBox {
  width: 100%;
  position: relative;
  .inputCon {
    width: 100%;
    height: 66px;
    background: #ffffff;
    display: flex;
    font-size: 24px !important;
    font-weight: 400 !important;
    align-items: center;
    border: 2px solid #b4272b;
    padding-left: 21px;
    border-radius: 6px;
    &::placeholder {
      font-size: 24px;

      font-weight: 400;
      color: #c6c6c6;
    }
  }
  img {
    background-color: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    height: 50px;
    width: 30px;
    position: absolute;
    height: 30px;
    top: 50%;
    transform: translateY(-50%);
    right: 20px;
  }
}
</style>
