<template>
  <!-- $%2 -->
  <div
    v-show="!$route.query.title && showHead"
    class="goodsDetailsHeader"
    :class="{ zidingyiheadText: iCustomizdde }"
  >
    <div class="headerReturnOperationContainer" v-if="!icon" @click="goBack">
      <van-icon name="arrow-left" />
    </div>
    <slot v-else name="left-icon">
      <!-- 页面头部 -->
    </slot>
    <div class="headBox">
      <slot></slot>
    </div>

    <slot name="right-text">
      <!-- 页面头部 -->
    </slot>
  </div>
</template>
<script>
export default {
  props: {
    // 这个参数表示是否自定义左侧图标,false为自定义
    icon: {
      default: false,
    },
    // 这个参数表示是否自定义内容,false为自定义
    iCustomizdde: {
      default: false,
    },
    // 自定义点击事件
    myClick: {
      default: null,
    },
  },
  name: "HeadGoBackVue",
  data() {
    return {};
  },
  methods: {
    goBack() {
      if (this.myClick) {
        return this.myClick();
      }
      this.$router.go(-1);
    },
  },
  computed: {
    //兼容朱工显示的参数
    showHead() {
      return !this.$route.query.nohead;
    },
  },
};
</script>
<style lang="scss" scoped>
// .dianzi {
//   height: 100px;
// }
.goodsDetailsHeader {
  display: flex;
  width: 100%;
  min-height: 100px;
  padding: 30px;
  align-items: center;
  position: sticky;
  z-index: 4;
  top: 0;

  background-color: white;

  .headerReturnOperationContainer {
    // border: solid;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 48px;
    height: 48px;
    margin-right: 24px;
  }

  .headBox {
    width: 100%;
  }
}

// .rightBox {
//   width: 100px;
// }

:deep().van-icon-arrow-left {
  font-size: 35px;

  color: #333333;
}

.goodsDetailsHeader.zidingyiheadText {
  .headerReturnOperationContainer {
    position: absolute;
  }

  .headBox {
    display: flex;
    justify-content: center;
    align-items: center;

    :deep() * {
      font-size: 32px;
      font-weight: 600;
    }
  }
}
</style>
