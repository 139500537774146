<template>
  <div class="paaa">
    <headBackBar :iCustomizdde="true" :icon="true">
      <template v-slot:left-icon>
        <van-icon name="arrow-left" @click="$fun.routerToPage('/user/index')" />
      </template>
      <template #default>
        <div class="search">
          <inputBoxVue
            type="text"
            :change="changeinput"
            :placeholder="$fanyi('请输入关键词搜索')"
            v-model="datas.porder_sn"
            :keydownEnter="search"
          />
        </div>
      </template>
    </headBackBar>
    <!--订单单列表 -->
    <ul class="tableList">
      <van-pull-refresh
        v-model="refreshing"
        @refresh="onRefresh"
        head-height="100"
        :loosing-text="$fanyi('释放即可刷新...')"
        :pulling-text="$fanyi('下拉刷新')"
        :loading-text="$fanyi('加载中')"
        :success-text="$fanyi('加载成功')"
      >
        <van-list
          v-model:loading="loading"
          :loading-text="$fanyi('加载中')"
          :finished="finished"
          @load="onLoad"
        >
          <li v-for="(item, index) in tableData" :key="index">
            <div>
              <h4
                v-longpress="
                  () => {
                    copySn(item.order_sn);
                  }
                "
              >
                <span>{{ $fanyi("售后单号") }}：</span>
                <span>
                  {{ item.after_sale_sn }}
                </span>
              </h4>
              <div class="churujinDetail">
                <div>
                  <p>
                    {{ $fanyi("配送单号") }}：<span>{{ item.porder_sn }}</span>
                  </p>
                  <p>
                    <!-- 负数是退款,正数是追收 -->
                    {{ $fanyi("赔款金额") }}：<span class="colorRed"
                      >{{ item.totalRefundToUserAmount }}円</span
                    >
                  </p>
                  <p>
                    {{ $fanyi("售后完成时间") }}：<span>{{
                      item.complete_at
                    }}</span>
                  </p>
                </div>
                <div class="userBalance">
                  <button
                    @click="
                      $fun.routerToPage(
                        '/aftersaledetails?after_sale_sn=' + item.after_sale_sn
                      )
                    "
                  >
                    {{ $fanyi("查看详情") }}
                  </button>
                </div>
              </div>
            </div>
          </li>
        </van-list>
      </van-pull-refresh>
    </ul>
    <div class="noData" v-if="!tableData.length">
      <div>
        <img :src="require('@/assets/icon/crj.png')" alt="" />
        <span>{{ $fanyi("暂无历史记录") }}</span>
      </div>
    </div>
  </div>
</template>
<script setup>
import headBackBar from "@/components/headGoBack/index.vue";
import inputBoxVue from "@/components/inputBox/index.vue";
import { getCurrentInstance, ref } from "vue";
import { Toast } from "vant";
const { proxy } = getCurrentInstance();

//------------------------ data -------------------------------------

// 加载数据
const loading = ref(false);
const finished = ref(false);
const refreshing = ref(false);
// 表格数据
const tableData = ref([]);
// 请求接口参数
const datas = ref({
  pageSize: 10,
  page: 0,
  porder_sn: "",
});
Toast.loading({
  duration: 0,
  message: proxy.$fanyi("加载中..."),
  forbidClick: true,
});
const onRefresh = () => {
  if (finished.value == true) {
    Toast(proxy.$fanyi("没有更多了"));
    refreshing.value = false;
  } else {
    resetTheTableData();
    onLoad();
  }
};
//------------------------ methods -------------------------------------
// 重置表格数据
const resetTheTableData = () => {
  datas.value.page = 0;
  tableData.value = [];
};
// 搜索
const search = () => {};
// 加载
const onLoad = async () => {
  datas.value.page++;
  const res = await proxy.$api.aftersaleList(datas.value);
  // console.log(res.data);
  if (res.code !== 0) {
    finished.value = true;
    Toast.fail(proxy.$fanyi(res.msg));
    Toast.clear();
  }
  loading.value = false;
  Toast.clear();
  // 进项判断是上下拉还是上划
  if (refreshing.value == true) {
    tableData.value = [...res.data.data, ...tableData.value];
    refreshing.value = false;
  } else {
    tableData.value = [...tableData.value, ...res.data.data];
  }
  if (
    tableData.value.length == res.data.total ||
    res.data?.data.length < datas.value.pageSize
  ) {
    finished.value = true;
  }
};
// 复制单号
const copySn = (order_sn) => {
  navigator.clipboard.writeText(order_sn);
  proxy.$message.primary(proxy.$fanyi("复制成功"));
};
</script>
<style lang="scss" scoped="scoped">
@import "@/css/mixin.scss";
.paaa {
  background-color: #f7f7f7;
  min-height: 100vh;
}
.search {
  width: 570px;
}
.tableList {
  margin: 0 30px;
  padding: 20px 0 30px;
  li {
    width: 690px;
    background: #ffffff;
    border-radius: 6px;
    padding: 30px;
    margin-bottom: 15px;

    h4 {
      width: 110px;
      height: 27px;
      display: flex;
      line-height: 1;
      align-items: center;
      width: 100%;
      font-size: 28px;
      margin-bottom: 12px;
      span {
        font-size: 28px;
      }
    }

    .churujinDetail {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      color: #999999;

      p {
        font-size: 24px;
        margin: 23px 0;
        line-height: 1;
        display: flex;
        align-items: center;
        span {
          font-size: 24px;
          color: black;
        }
        .colorRed {
          color: #b4272d;
        }
        &:last-child {
          margin-bottom: 0;
        }
      }

      .orderSn {
        margin-top: 41px;
        margin-bottom: 0;

        span {
          font-size: 24px;
        }

        .orderSnFont {
          color: #010101;
          font-size: 24px;
        }
      }

      .userBalance {
        button {
          width: 160px;
          height: 60px;
          background: #b4272d;
          border-radius: 6px;
          font-size: 24px;
          color: white;
          font-weight: 400;
        }
      }
    }
    .rechargeDeposit {
      h4 {
        margin-bottom: 20px;
        span {
          font-size: 28px;
          font-weight: 400;
          line-height: 30px;
          color: #000000;
        }
      }
      .rujinName {
        display: block;
        font-size: 24px;
        line-height: 1;
        margin-bottom: 10px;
        color: #999999;
        text-align: right;
      }
      .timeAndAmount {
        display: flex;
        justify-content: space-between;
        align-items: center;
        span {
          font-size: 24px;
          font-weight: 400;
          color: #999999;
        }
        span.amount {
          font-size: 30px;
          font-weight: 600;
        }
      }
    }
  }
}
.noData {
  padding-top: 181px;
  div {
    display: flex;
    align-items: center;
    flex-direction: column;
    img {
      width: 294px;
      height: 265px;
      margin-bottom: 50px;
    }
    span {
      font-size: 24px;
      line-height: 36px;
      color: rgba(153, 153, 153, 1);
    }
  }
}
</style>
